import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPagev2 from "../src/Pages/LandingPagev2";
import Email_Warmup_LandingPage from "Pages/Email_Warmup_LandingPage";
import LoadingPagev1 from "../src/Pages/LoadingPagev1";
import PricingPagev2 from "../src/Pages/PricingPagev2";
import Blogpagev3_Programatic_Seo from "../src/Pages/Blogpagev3_Programatic_Seo";
import Why_is_my_mailchimp_email_going_to_spam from "../src/Pages/Blogs/Why_is_my_mailchimp_email_going_to_spam";
import DataState from "ContextAPI/DataContext";
import Dashboardv2 from "../src/Pages/Dashboardv2";
import EmailSpamChecker from "../src/Pages/EmailSpamChecker";
import DashboardView from "Pages/Dashboardv2/DashboardPage";
import Dashboardv3 from "Pages/Dashboardv3";

import Login from "Pages/Login";
import Loginv2 from "Pages/Loginv2";

// import AppPassword from "drawers/AppPassword";
import AppPassword from "drawers/AppPassword";
import AppPasswordv2 from "drawers/AppPasswordv2";
import GoogleSignup_API from "Pages/Auth/GoogleSignup_API";
import GoogleAccountAccess from "Pages/Auth/GoogleAccountAccess";
import EmailConnectStatusPage from "Pages/Auth/EmailConnectStatusPage";
import PrivacyPolicy from "Pages/PrivacyPolicy";

const ExtensionLandingPage = React.lazy(() =>
  import("../src/Pages/ExtensionLandingPage")
);
const HomeProcessing = React.lazy(() => import("../src/Pages/HomeProcessing"));
const ProjectRoutes = () => {
  return (
    <DataState>
      <React.Suspense fallback={<>Loading...</>}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPagev2 />} />
            <Route
              path="/email-warmup"
              element={<Email_Warmup_LandingPage />}
            />

            {/* <Route path="*" element={<NotFound />} /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/login" element={<Loginv2 />} />

            <Route path="/test" element={<AppPassword />} />
            <Route path="/test2" element={<AppPasswordv2 />} />
            {/* email-warmup ==> warm-up */}
            <Route path="/warm-up" element={<LoadingPagev1 />} />
            {/*  */}
            <Route
              path="/automated-email-warm-up"
              element={<PricingPagev2 />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* <Route path="/dashboard" element={<DashboardView />} /> */}
            <Route path="/dashboard" element={<Dashboardv3 />} />

            <Route
              path="/check-email-for-spam-triggers"
              element={<EmailSpamChecker />}
            />
            <Route
              path="/link1"
              element={<Why_is_my_mailchimp_email_going_to_spam />}
            />

            <Route
              path="/sendgrid-emails-going-to-spam"
              element={<Blogpagev3_Programatic_Seo />}
            />

            <Route path="/email-onboard" element={<EmailConnectStatusPage />} />
          </Routes>
        </Router>
      </React.Suspense>
    </DataState>
  );
};
export default ProjectRoutes;
