// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// const firebaseConfig = {
//   apiKey: "AIzaSyCPDCd6I4dsupHoqoOwXBpfKwr5Ue4SMic",
//   authDomain: "autoemailwarmup.firebaseapp.com",
//   databaseURL: "https://autoemailwarmup-default-rtdb.firebaseio.com",
//   projectId: "autoemailwarmup",
//   storageBucket: "autoemailwarmup.appspot.com",
//   messagingSenderId: "500392173422",
//   appId: "1:500392173422:web:0eecc4e98786f26b82eb7f",
//   measurementId: "G-38CDN4JLTF",
// };
const firebaseConfig = {
  apiKey: "AIzaSyAmsPFrgwGAUhwVRq8MMwV-Equ-UB0rFW0",
  authDomain: "autoemailsoftware.firebaseapp.com",
  projectId: "autoemailsoftware",
  storageBucket: "autoemailsoftware.appspot.com",
  messagingSenderId: "946340253059",
  appId: "1:946340253059:web:751b6f5edc28c475b1fab6",
  databaseURL: "https://autoemailsoftware-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
// const microsoftProvider = new MicrosoftAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");
microsoftProvider.setCustomParameters({
  prompt: "consent",
  tenant: "fd0d7051-f204-4441-8463-d3030f498906", // Replace with your tenant ID if necessary
});

const db = getFirestore(app);

// export { db };
export { auth, googleProvider, microsoftProvider, db };
